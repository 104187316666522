// @ts-strict-ignore
import React from 'react';
import dynamic from 'next/dynamic';
import { useVerifyLoggedIn } from 'bb/account';
import { fetchNotifications } from 'bb/api/server/contentful';
import { fetchCountdown } from 'bb/api/server/contentful/fetchCountdown';
import { fetchFaq } from 'bb/api/server/contentful/fetchFaq';
import { fetchImage } from 'bb/api/server/contentful/fetchImage';
import { type SerializedCountdownEntity } from 'bb/api/server/contentful/types';
import { type NextWebRequest } from 'bb/api/server/types';
import { getValue } from 'bb/api/utils/promise';
import { ActivateOptimizelyScript } from 'bb/app/analytics';
import { Link } from 'bb/app/nav';
import { useRouter } from 'bb/app/router';
import { Dehydrate, observer } from 'bb/app/stores';
import { Notification } from 'bb/common/components/notifications';
import { Faq } from 'bb/common/faq';
import { Head } from 'bb/common/head/Head';
import { type ImageData } from 'bb/common/images/types';
import {
    type FaqList,
    type Notification as NotificationType
} from 'bb/common/types';
import { useHasFeature } from 'bb/config/features';
import { fetchFeature } from 'bb/config/features/server';
import { type Market, getCmsKeyByMarket } from 'bb/config/marketConfigUtils';
import { Cookies } from 'bb/cookies/config';
import { DiscoveryLayout } from 'bb/discovery/components/DiscoveryLayout';
import {
    SignupTop,
    StartUsps,
    HowItWorks,
    StartAppView,
    Subscriptions,
    PromoBlock,
    HeroBottom
} from 'bb/discovery/start/components/sections';
import { fetchStartPageAppView } from 'bb/discovery/start/server';
import { type StartPageAppView } from 'bb/discovery/start/types';
import { HttpStatusCode } from 'bb/page/server/consts';
import {
    createServerSideProps,
    setCacheControlHeader,
    type GetServerSideData
} from 'bb/page/server/createServerSideProps';
import { hasCampaignCode } from 'bb/page/server/query';
import { redirect } from 'bb/page/server/redirect';
import { type PageProps, type PageWithLayout } from 'bb/page/types';
import { useSubscriptionStore } from 'bb/subscription';
import { fetchSubscriptionProducts } from 'bb/subscription/server/fetchSubscriptionProducts';
import { type SubscriptionProducts } from 'bb/subscription/types';
import { Page, Spinner } from 'bb/ui';
import { isServer } from 'bb/utils';

const LazyStickyCountdown = dynamic(
    () =>
        import('bb/campaigns/components/StickyCountdown').then(
            (m) => m.StickyCountdown
        ),
    { ssr: true }
);

type StartPageData = {
    images: ImageData[];
    notifications: NotificationType[];
    subscriptionProducts: SubscriptionProducts;
    appView: StartPageAppView;
    faq: FaqList[];
    fetchAppViewOnClient: boolean;
    fetchSubscriptionProductsOnClient: boolean;
    countdown: SerializedCountdownEntity | null;
};

type StartPageProps = PageProps & StartPageData;

const StartPage: PageWithLayout<StartPageProps> = observer(
    ({
        images,
        subscriptionProducts,
        appView,
        notifications,
        faq,
        fetchAppViewOnClient,
        countdown
    }) => {
        const { routes } = useRouter();
        const subscriptionStore = useSubscriptionStore();
        const hasFeature = useHasFeature();
        const { data: { isLoggedIn } = {} } = useVerifyLoggedIn();

        if (isServer()) {
            subscriptionProducts &&
                subscriptionStore.setSubscriptionProducts(subscriptionProducts);
        }
        const heroImage = images?.find(
            (img) => img.position === 'nextStartTop'
        );
        const subHeroImage = images?.find(
            (img) => img.position === 'nextStartFull'
        );

        const noSubscriptionProducts =
            subscriptionStore.subscriptionProducts.products.length === 0;
        const { trialDays, trialHours } =
            subscriptionStore?.subscriptionProducts || {};

        return (
            <Page data-optimizely="start-page">
                <ActivateOptimizelyScript />

                {countdown &&
                    hasFeature('nextweb-countdown') &&
                    !isLoggedIn && (
                        <Link
                            route={routes.registration}
                            composedComponent={(linkProps) => (
                                <LazyStickyCountdown
                                    header={countdown.header}
                                    date={countdown.endDate}
                                    {...linkProps}
                                />
                            )}
                        />
                    )}
                <Head withAlternateLinks route={routes.start} />
                <Dehydrate store={subscriptionStore} />
                <Spinner
                    show={noSubscriptionProducts}
                    style={{ backgroundColor: 'var(--color-primary-white)' }}
                />

                <SignupTop
                    headerProps={{ as: 'h1' }}
                    image={heroImage}
                    trialDays={trialDays}
                />
                <StartUsps image={subHeroImage} />
                <StartAppView
                    appView={appView}
                    fetchOnClient={fetchAppViewOnClient}
                />
                <HowItWorks />
                <Subscriptions trialDays={trialDays} trialHours={trialHours} />
                <PromoBlock images={images} />
                <Faq faq={faq} data-optimizely="startpage-faq" />
                <HeroBottom />
                <Notification
                    notifications={notifications}
                    typesAllowed={['giftcard', 'general']}
                />
            </Page>
        );
    }
);

const getData: GetServerSideData<StartPageData> = async (ctx, market) => {
    const { req, query } = ctx;

    if (req.cookies[Cookies.TOKEN_COOKIE])
        return redirect(HttpStatusCode.TEMPORARY_REDIRECT, 'welcome', {
            market: market as Market
        });
    if (hasCampaignCode(query)) {
        const { market: _, ...rest } = query;
        return redirect(HttpStatusCode.TEMPORARY_REDIRECT, 'promocode', {
            market: market as Market,
            ...rest
        });
    }

    const cmsKey = getCmsKeyByMarket(market);

    const required = Promise.all([
        fetchSubscriptionProducts(req as NextWebRequest),
        fetchStartPageAppView(req as NextWebRequest),
        fetchFaq(cmsKey, 'FAQ') as Promise<FaqList[]>,
        fetchNotifications(cmsKey) as Promise<NotificationType[]>
    ]);

    const images = Promise.allSettled([
        fetchImage(cmsKey, 'nextStartTop'),
        fetchImage(cmsKey, 'nextStartFull'),
        fetchImage(cmsKey, 'promoBlockImage'),
        fetchImage(cmsKey, 'studentPromoImage')
    ]);

    const hasCountdownFeature = await fetchFeature('nextweb-countdown');

    const [
        [subscriptionProducts, appView, faq, notifications],
        imagesData,
        countdown
    ] = await Promise.all([
        required,
        images,
        hasCountdownFeature ? fetchCountdown(ctx, 'start', true) : null
    ]);

    setCacheControlHeader(ctx, { maxAge: 600 });

    return {
        props: {
            images: imagesData.map(getValue).filter(Boolean),
            subscriptionProducts,
            appView,
            notifications,
            faq,
            fetchAppViewOnClient: !appView,
            fetchSubscriptionProductsOnClient: !subscriptionProducts,
            countdown
        }
    };
};

export const getServerSideProps = createServerSideProps<StartPageData>(
    [
        'nav',
        'footer',
        'start',
        'common',
        'cta',
        'subscription',
        'student',
        'faq',
        'books',
        'inputFields',
        'bookCard',
        'campaign'
    ],
    getData
);

StartPage.getLayout = (content) => <DiscoveryLayout>{content}</DiscoveryLayout>;

export default StartPage;
